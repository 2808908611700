<template>
  <div></div>
</template>

<script>
import axios from 'axios'
import LiffChecker from "@/utils/LiffChecker";

export default {
  mounted() {
    this.fetchFile()
  },
  methods: {
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    handleFileUrl(url) {
      console.log(url)
      window.location.href = url
    },
    async fetchFile() {
      let { org_code: orgCode, uploadFile: fileId } = this.$route.params
      let url = process.env.VUE_APP_API_BASE_URL + "/" + orgCode + "/liff/upload-file/" + fileId + "/download";
      try {
        const { data } = await axios.get(url)
        if (data.is_friend) {
          console.log(data)
          const checker = new LiffChecker(orgCode);
          await checker.initLiff()
          await this.sleep(1000)
          this.handleFileUrl(data.url)
        } else {
          this.handleFileUrl(data.url)
        }
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Error",
          text: "檔案已失效",
          icon: "error",
          confirmButtonText: "返回"
        }).then(() => {
          this.$router.go(-1)
        })
      }
    }
  }
}
</script>
